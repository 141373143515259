
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import "@procesio/procesio-design-system/dist/procesioui.css";

import Template from "@/layout/Template.vue";
import {
  ButtonComponent,
  DropdownComponent,
  DropdownItemComponent,
  IconComponent,
} from "@procesio/procesio-design-system";

import Modal from "./components/Modal/Modal.vue";

import VueToastr from "@deveodk/vue-toastr";
import "@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css";
Vue.use(VueToastr, {
  defaultPosition: "toast-top-right",
  defaultTimeout: 3000,
});

import VTooltip from "v-tooltip";
Vue.use(VTooltip, {
  defaultTrigger: "hover focus click",
});

@Component({
  components: {
    Template,
    "pds-button": ButtonComponent,
    "pds-dropdown": DropdownComponent,
    "pds-dropdown-item": DropdownItemComponent,
    "pds-icon": IconComponent,
    Modal,
  },
})
export default class HelloWorld extends Vue {
  get routeName() {
    return this.$route.name;
  }

  get isFullScreen() {
    const routes = ["UIT Tracker"];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return routes.includes(this.routeName!);
  }

  showError = false;

  @Watch("$route")
  onChildChanged(val: Record<string, any>) {
    console.log(val);
    this.guardAuth(val);
  }

  mounted() {
    console.log("Force Deploy");
    console.log("BOOTED");
    console.log(this.$route);

    this.guardAuth(this.$route as any);
  }

  guardAuth(route: Record<string, unknown>) {
    const { clientid, codrod } = route.query as Record<string, unknown>;

    if (!clientid && !codrod) {
      this.showError = true;
    }

    if (route.name === "Unauthorized" || route.name === "UIT Tracker") {
      return;
    }

    const { guid, credentials } = route.query as Record<string, unknown>;

    const pallexGuid = this.getCookieValue("etransport-guid");

    if (!pallexGuid || !guid || pallexGuid !== guid) {
      this.$router.push("/unauthorized");
    }
  }

  backBtnHandler() {
    window.location.assign("https://clientplus.pallex.ro/pallets");
  }

  getCookieValue(name: string) {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);

    const match = document.cookie.match(regex);
    if (match) {
      return match[2];
    }
  }
}

declare module "vue/types/vue" {
  // 3. Declare augmentation for Vue
  interface Vue {
    $toastr(...args: string[]): void;
  }
}
